import bottle from './bottle.png'
import dot1 from './circle_1.png'
import dot2 from './circle_2.png'
import dot3 from './circle_3.png'
import borderline from './borderline.png'
import shadow from './shadow.png'
import linee from './line.jpg'
import cbtn from './btn.png'
import './App.css';
import Button from '@material-ui/core/Button';
import { useState,useEffect } from 'react';
import Slider from '@material-ui/core/Slider';
import { makeStyles } from '@material-ui/core/styles';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

function valuetext(value) {
  return `${value}`;
}
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const useStyles = makeStyles({
  root: {
    height: 300,
  },
});


function App() {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickOpen2 = () => {
    setOpen2(true);
  };
  const handleClose = () => {
    setOpen(false);
    setOpen2(false);

  };


  const classes = useStyles();

  const [values, setvalues] = useState([3,9,29,46,52,64,74,92,100])
  const [slieers, setslieers] = useState([3,9,29,46,52,64,74,92,100])
  const [karatype, setkaratype] = useState("Kara Classic")
  const [defkara, setdefkara] = useState(null)
  const [links, setlinks] = useState("https://www.madebysofi.com/products/kara-powerful-source-of-greens?pr_prod_strat=collection_fallback&pr_rec_pid=5294076690476&pr_ref_pid=6664235221036&pr_seq=uniform")

  const coeff = 2.3;
  const constf = 0;
  const [title, settitle] = useState("")
  const [content, sentcontent] = useState("")
  const stress = [5,4,6]
  const immunity = [2,3,4]
  const focus = [0,1]
  const skin = [7,4,5]
  const digestion = [8,7,6]

  const indexOfMax = (arra) => {
    var arr = [...arra];
    var maxIndex = 0;
    var max = arr[0];
    var tempres=[]
      for (var i = 1; i < arr.length; i++) {
          if (arr[i] > max) {
            max = arr[i];
            maxIndex = i;
          }
      }
      arr[maxIndex]=0;
      tempres.push(maxIndex);
      var maxIndex = 0;
      var max = arr[0];
      for (var j = 1; j < arr.length; j++) {
        if (arr[j] > max) {
          max = arr[j];
          maxIndex = j;
        }
    }
    arr[maxIndex]=0;
    tempres.push(maxIndex);
    var maxIndex = 0;
    var max = arr[0];
    for (var z = 1; z < arr.length; z++) {
      if (arr[z] > max) {
        max = arr[z];
        maxIndex = z;
      }
  }
  arr[maxIndex]=0;
  tempres.push(maxIndex);
    return tempres;
}

  useEffect(() => {
    setkaratype("KARA Original");
    setdefkara(null);
    setslieers([values[0]*coeff+constf, 
      (values[1]-values[0])*coeff+constf, 
      (values[2]-values[1])*coeff+constf,
      (values[3]-values[2])*coeff+constf,
      (values[4]-values[3])*coeff+constf,
      (values[5]-values[4])*coeff+constf,
      (values[6]-values[5])*coeff+constf,
      (values[7]-values[6])*coeff+constf,
      (values[8]-values[7])*coeff+constf
    ])


    const scores=[]
    let tempscore = 0
    stress.forEach(item=>{
      tempscore+=slieers[item]
    })
    scores.push({"amount":tempscore})
    tempscore = 0
    immunity.forEach(item=>{
      tempscore+=slieers[item]
    })
    scores.push({"amount":tempscore})

    tempscore = 0
    skin.forEach(item=>{
      tempscore+=slieers[item]
    })
    scores.push({"amount":tempscore})
    tempscore = 0
    focus.forEach(item=>{
      tempscore+=slieers[item]
    })
    scores.push({"amount":tempscore})
    tempscore = 0
    digestion.forEach(item=>{
      tempscore+=slieers[item]
    })
    scores.push({"amount":tempscore})

    let maxa=scores[0].amount
    let loopcounter=0
    let maxidx=0

    scores.forEach(score=>{
      if (score.amount>maxa){
        maxidx=loopcounter
        maxa=score.amount
      }
      loopcounter++
    })

    const karatypesnames=[
      "Kara stress relief",
      "KARA Immunity",
      "Kara healthy skin",
      "Kara Focus n Clarity",
      "Kara digestion support"
    ]
    const karatypeslinkss=[
      "https://www.madebysofi.com/collections/heart-beats-health-beauty/products/custom-kara-br-p-style-font-size-15px-strong-powerful-source-of-greens-strong-p?variant=39673894731820",
      "https://www.madebysofi.com/collections/heart-beats-health-beauty/products/custom-kara-br-p-style-font-size-15px-strong-powerful-source-of-greens-strong-p?variant=39673894764588",
      "https://www.madebysofi.com/collections/heart-beats-health-beauty/products/custom-kara-br-p-style-font-size-15px-strong-powerful-source-of-greens-strong-p?variant=39673894830124",
      "https://www.madebysofi.com/collections/heart-beats-health-beauty/products/custom-kara-br-p-style-font-size-15px-strong-powerful-source-of-greens-strong-p?variant=39673894797356",
      "https://www.madebysofi.com/collections/heart-beats-health-beauty/products/custom-kara-br-p-style-font-size-15px-strong-powerful-source-of-greens-strong-p?variant=39673894862892"
    ]
    setlinks(karatypeslinkss[maxidx])

    setkaratype(karatypesnames[maxidx])
  }, [values])


  const openlink = ()=>{
    window.open(links, "_blank")
  }
  return (
    <div className="App">
    <div className="overlay"> <p>Please use a pc to view this page</p> </div>
    <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {title}
        </BootstrapDialogTitle>
        <DialogContent dividers>
        <p >{content}</p>
        </DialogContent>
      </BootstrapDialog>


      <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open2}
    >
      <DialogContent dividers>
        <h3 style={{textAlign:"center"}}>FIND YOUR BEST KARA TYPE</h3> 
        <p style={{textAlign:"center"}}>Personalize your ingredient amounts to find out which type of KARA is best for you.</p>
        <br />
        <h3 style={{textAlign:"center"}}>HOW IT WORKS</h3> 
        <p>1. Slide handles up and down to adjust amount for each ingredient based on your preference.</p> 
        <p>2. As you change ingredients, your recommended KARA type will show below.</p> 
        <Button className="prbtn" variant="contained" style={{width:"fit-content"}} onClick={handleClose}> Proceed </Button>
        </DialogContent>
    </BootstrapDialog>


      <header className="App-header">
        <div className="inittext">
        <h3 style={{textAlign:"center"}}>FIND YOUR BEST KARA TYPE</h3> 
        <p style={{textAlign:"center"}}>Personalize your ingredient amounts to find out which type of KARA is best for you.</p>
        <br />
        <h3 style={{textAlign:"center"}}>HOW IT WORKS</h3> 
        <p>1. Slide handles up and down to adjust amount for each ingredient based on your preference.</p> 
        <p>2. As you change ingredients, your recommended KARA type will show below.</p> 
        <br />
        <br />

        </div>
        <div className="bottlecont">
        
          <div className="bottle filelr">
            <img src={bottle} style={{zIndex:100, position: "relative"}} alt="kara bottWle" className="bottleimg"/>
            <div className="cnts" style={{marginTop:-350,
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        }}>
              <div className="contentf cnt" style={{height:Math.max(260-slieers.reduce((a, b) => a + b, 0), 0) ,width:158,display: "flex", justifyContent: 'center', alignItems:'center'}}></div>
              <div style={{display:"flex",zIndex:"20",marginBottom:"-9px"}}> <div className="content10 cnt" style={{width:158,display: "flex", justifyContent: 'center', alignItems:'center'}}> </div></div>
              <div className="cntdiv" style={{display:"flex",marginLeft: 154}}> <div className="content9 cnt" style={{height:slieers[8],width:158,display: "flex", justifyContent: 'center', alignItems:'center', fontSize: Math.min(slieers[8]/3+5, 20)}}>Cardamom </div> <img src={linee} width="20px" height="2px"/> <p style={{color:"black",marginTop:-8,marginBottom:0, fontSize:10,marginLeft:15}}><span className="ingtext">Balanced digestion</span><HelpOutlineIcon onClick={()=>{settitle("CARDAMOM – Balanced digestion");sentcontent("Anti-inflammatory and digestive support to relieve stomach irregularities."); handleClickOpen()}} style={{height:12, marginLeft:-3, cursor:"pointer"}} /> </p></div>
              <div className="cntdiv" style={{display:"flex",marginLeft: 195}}><div className="content8 cnt" style={{height:slieers[7],width:158,display: "flex", justifyContent: 'center', alignItems:'center', fontSize: Math.min(slieers[7]/3+5, 20)}}>Turmeric</div> <img src={linee} width="20px" height="2px"/><p style={{color:"black",marginTop:-8,marginBottom:0, fontSize:10,marginLeft:15}}><span className="ingtext">Clean skin, proper digestion</span><HelpOutlineIcon  onClick={()=>{settitle("TURMERIC – Clean skin, proper digestion");sentcontent("Excellent antioxidant properties. Known for its positive effect on longevity, skin health and digestion by significantly reducing inflammation."); handleClickOpen()}}  style={{height:12, marginLeft:-3, cursor:"pointer"}} /> </p></div>
              <div className="cntdiv" style={{display:"flex",marginLeft: 214}}> <div className="content7 cnt" style={{height:slieers[6],width:158,display: "flex", justifyContent: 'center', alignItems:'center', fontSize: Math.min(slieers[6]/3+5, 20)}}>Lemongrass</div> <img src={linee} width="20px" height="2px"/> <p style={{color:"black",marginTop:-8,marginBottom:0, fontSize:10,marginLeft:15}}><span className="ingtext">Relaxed mind, healthy stomach</span><HelpOutlineIcon onClick={()=>{settitle("LEMONGRASS – Relaxed mind, healthy stomach");sentcontent("Loaded with “Eugenol”, compound known to relieve stress, anxiety, and headaches. Excellent for its detoxifying and antifungal abilities."); handleClickOpen()}}  style={{height:12, marginLeft:-3, cursor:"pointer"}} /> </p></div>
              <div className="cntdiv" style={{display:"flex",marginLeft: 178}}><div className="content6 cnt" style={{height:slieers[5],width:158,display: "flex", justifyContent: 'center', alignItems:'center' , fontSize:Math.min(slieers[5]/3+5, 20)}} >Basil</div><img src={linee} width="20px" height="2px"/><p style={{color:"black",marginTop:-8,marginBottom:0, fontSize:10,marginLeft:15}}><span className="ingtext">Calm mind, smooth skin</span><HelpOutlineIcon onClick={()=>{settitle("BASIL – Calm mind, smooth skin");sentcontent("Basil has antibacterial properties and contains high levels of antioxidants, which support skin health. Restores mental alertness by reducing symptoms like brain fog and mood swings."); handleClickOpen()}}  style={{height:12, marginLeft:-3, cursor:"pointer"}} /> </p></div>
              <div className="cntdiv" style={{display:"flex",marginLeft: 295}}> <div className="content1 cnt" style={{height:slieers[4],width:158,display: "flex", justifyContent: 'center', alignItems:'center', fontSize:Math.min(slieers[4]/3+5, 20)}}> Saffron</div><img src={linee} width="20px" height="2px"/> <p style={{color:"black",marginTop:-8,marginBottom:0, fontSize:10,marginLeft:15}}><span className="ingtext">Peaceful mind, everlasting body and skin health</span><HelpOutlineIcon onClick={()=>{settitle("SAFFRON – Peaceful mind, everlasting body and skin health");sentcontent("Saffron, the World’s most expensive and powerful spice, promotes better brain function, healthy skin, and body. Cosmetic benefits are especially the ability to brighten, clear and protect skin against UV radiation and shield off ultraviolet A and B Rays, which cause production of free radicals and cause premature skin aging."); handleClickOpen()}}  style={{height:12, marginLeft:-3, cursor:"pointer"}} /> </p></div>
              <div className="cntdiv" style={{display:"flex",marginLeft: 148}}> <div className="content2 cnt" style={{height:slieers[3],width:158,display: "flex", justifyContent: 'center', alignItems:'center', fontSize:Math.min(slieers[3]/3+5, 20)}}>Ginger</div><img src={linee} width="20px" height="2px"/><p style={{color:"black",marginTop:-8,marginBottom:0, fontSize:10,marginLeft:15}}><span className="ingtext">Cleanse and detox</span><HelpOutlineIcon onClick={()=>{settitle("GINGER – Cleanse and detox");sentcontent("Antifungal, anti-inflammatory, and antiseptic ability. Helps relieve symptoms of cold and flu. Helps removing toxins, bacteria, and other harmful substances by supporting correct liver function."); handleClickOpen()}}  style={{height:12, marginLeft:-3, cursor:"pointer"}} /> </p></div>
              <div className="cntdiv" style={{display:"flex",marginLeft: 178}}> <div className="content3 cnt" style={{height:slieers[2],width:158,display: "flex", justifyContent: 'center', alignItems:'center', fontSize:Math.min(slieers[2]/3+5, 20)}}> Garlic</div> <img src={linee} width="20px" height="2px"/> <p style={{color:"black",marginTop:-8,marginBottom:0, fontSize:10,marginLeft:15}}><span className="ingtext">Immune system booster</span><HelpOutlineIcon onClick={()=>{settitle("GARLIC – Immune system booster");sentcontent("Enhances immunity and antibody production to hundreds of symptoms. Protects against organ damage from heavy metal toxicity. Promotes healthy digestion and improves brain function by regulating correct blood flow."); handleClickOpen()}}  style={{height:12, marginLeft:-3, cursor:"pointer"}} /> </p></div>
              <div className="cntdiv" style={{display:"flex",marginLeft: 149}}> <div className="content4 cnt" style={{height:slieers[1],width:158,display: "flex", justifyContent: 'center', alignItems:'center', fontSize:Math.min(slieers[1]/3+5, 20)}} >Ashwagandha</div> <img src={linee} width="20px" height="2px"/> <p style={{color:"black",marginTop:-5,marginBottom:0, fontSize:10,marginLeft:15}}><span className="ingtext">Memory and focus</span><HelpOutlineIcon style={{zIndex:"20"}} onClick={()=>{settitle("ASHWAGANDHA – Memory and focus");sentcontent("Powerful Ayurvedic herb. Boosts energy levels, information processing speed, mental skills, as well as memory and focus. Best known to relieve stress and balance hormones."); handleClickOpen()}}  style={{height:12, marginLeft:-3, cursor:"pointer"}} /> </p></div>
              <div className="cntdiv" style={{display:"flex",marginLeft: 114}}> <div className="content5 cnt" style={{height:slieers[0]+15,width:158,borderRadius:"0 0 10px 10px", display: "flex", justifyContent: 'center', alignItems: "center",  fontSize:Math.min(slieers[0]/3+10, 20), paddingTop: 5}}>Ghost pepper</div> <img src={linee} width="20px" height="2px"/>  <p style={{color:"black",marginTop:-5,marginBottom:0, fontSize:10,marginLeft:15}}><span className="ingtext">Mind clarity</span><HelpOutlineIcon style={{zIndex:"20"}} onClick={()=>{settitle("GHOST PEPPER – Mind clarity");sentcontent("Improves mind clarity, learning skills and memory by energizing blood circulation. Fights common cold and flu like symptoms by increasing antibodies and boosting immunity."); handleClickOpen()}}  style={{height:12, marginLeft:-3, cursor:"pointer"}} /> </p></div>
              <img src={shadow} style={{zIndex:99, position: "relative",marginTop:10,marginLeft:-50}} alt="kara bottle" width="360px"/>


            </div>
          </div>

          <div className="slidercont">
            <Slider
            value={values[0]}
              orientation="vertical"
              onChange={(e,vall)=>{
                if(values[1]>=vall){
                  if((values[8]<97 || vall< values[0] )&& (values[0] <= 35|| vall< values[0] )){
                    const _temp=[...values];
                    _temp[8]-=values[0]-vall;
                    setvalues([..._temp])

                    _temp[1]-=values[0]-vall;
                    _temp[2]-=values[0]-vall;
                    _temp[3]-=values[0]-vall;
                    _temp[4]-=values[0]-vall;
                    _temp[5]-=values[0]-vall;
                    _temp[6]-=values[0]-vall;
                    _temp[7]-=values[0]-vall;
                    _temp[0]=vall;
                    setvalues([..._temp])
                  }
                }
              }}
              aria-labelledby="vertical-slider"
            />
              <Slider
              value={values[1]}
              orientation="vertical"
              onChange={(e,vall)=>{
                if( values[0]+3<vall && values[2]>vall){
                  if((values[8]<97 || vall<= values[1])&& (values[1]-values[0] <= 35|| vall< values[1] )){

                  const _temp=[...values];
                  _temp[8]-=values[1]-vall;
                  setvalues([..._temp])
                  _temp[2]-=values[1]-vall;
                  _temp[3]-=values[1]-vall;
                  _temp[4]-=values[1]-vall;
                  _temp[5]-=values[1]-vall;
                  _temp[6]-=values[1]-vall;
                  _temp[7]-=values[1]-vall;
                  _temp[1]=vall;
                  setvalues([..._temp])
                }}
              }}
             getAriaValueText={valuetext}
              aria-labelledby="vertical-slider"
            />
            <Slider
              value={values[2]}
              orientation="vertical"
              onChange={(e,vall)=>{
                if(values[1]+3<vall && values[3]>vall){
                  if((values[8]<97 || vall<= values[2])&& (values[2]-values[1] <= 35|| vall< values[2] )){

                  const _temp=[...values];
                  _temp[8]-=values[2]-vall;
                  setvalues([..._temp])
                  _temp[3]-=values[2]-vall;
                  _temp[4]-=values[2]-vall;
                  _temp[5]-=values[2]-vall;
                  _temp[6]-=values[2]-vall;
                  _temp[7]-=values[2]-vall;
                  _temp[2]=vall;
                  setvalues([..._temp])
                }}
              }}            
              getAriaValueText={valuetext}
              aria-labelledby="vertical-slider"
            />
            <Slider
              value={values[3]}
              orientation="vertical"
              onChange={(e,vall)=>{
                if(values[2]+3<vall && values[4]>vall){
                  if((values[8]<97 || vall<= values[3])&& (values[3]-values[2] <= 35|| vall< values[3] )){

                  const _temp=[...values];
                  _temp[8]-=values[3]-vall;
                  setvalues([..._temp])
                  _temp[4]-=values[3]-vall;
                  _temp[5]-=values[3]-vall;
                  _temp[6]-=values[3]-vall;
                  _temp[7]-=values[3]-vall;
                  _temp[3]=vall;
                  setvalues([..._temp])
                }}
              }}            
              getAriaValueText={valuetext}
              aria-labelledby="vertical-slider"
            />
            <Slider
              value={values[4]}
              orientation="vertical"
              onChange={(e,vall)=>{
                if(values[3]+3<vall && values[5]>vall){
                  if((values[8]<97 || vall<= values[4])&& (values[4]-values[3] <= 35|| vall< values[4] )){

                  const _temp=[...values];
                  _temp[8]-=values[4]-vall;
                  setvalues([..._temp])
                  _temp[5]-=values[4]-vall;
                  _temp[6]-=values[4]-vall;
                  _temp[7]-=values[4]-vall;
                  _temp[4]=vall;
                  setvalues([..._temp])
                }}
              }}            
              getAriaValueText={valuetext}
              aria-labelledby="vertical-slider"
            />
            <Slider
            value={values[5]}
            orientation="vertical"
            onChange={(e,vall)=>{
              if(values[4]+3<vall && values[6]>vall){
                if((values[8]<97 || vall<= values[5])&& (values[5]-values[4] <= 35|| vall< values[5] )){

                const _temp=[...values];
                _temp[8]-=values[5]-vall;
                setvalues([..._temp])
                _temp[6]-=values[5]-vall;
                _temp[7]-=values[5]-vall;
                _temp[5]=vall;
                setvalues([..._temp])
              }}
            }}            
            getAriaValueText={valuetext}
            aria-labelledby="vertical-slider"
          />
          <Slider
          value={values[6]}
          orientation="vertical"
          onChange={(e,vall)=>{
            if(values[5]+3<vall && values[7]>vall){
              if((values[8]<97 || vall<= values[6])&& (values[6]-values[5] <= 35|| vall< values[6] )){

              const _temp=[...values];
              _temp[8]-=values[6]-vall;
              setvalues([..._temp])
              _temp[7]-=values[6]-vall;
              _temp[6]=vall;
              setvalues([..._temp])
            }}
          }}            
          getAriaValueText={valuetext}
          aria-labelledby="vertical-slider"
        />
        <Slider
        value={values[7]}
        orientation="vertical"
        onChange={(e,vall)=>{
          if(values[6]+3<vall && values[8]>vall){
            if((values[8]<97 || vall<= values[7])&& (values[7]-values[6] <= 35|| vall< values[7] )){

            const _temp=[...values];
            _temp[8]-=values[7]-vall;
            _temp[7]=vall;
            setvalues([..._temp])
          }}
        }}     
     
        getAriaValueText={valuetext}
        aria-labelledby="vertical-slider"
      />
      <Slider
        value={values[8]}
        orientation="vertical"
        onChange={(e,vall)=>{
          if((values[7]+3<vall)&& ((values[8]-values[7]) < 35|| vall< values[8] ) ){

            const _temp=[...values];
            _temp[8]=vall;
            setvalues([..._temp])
          }
        }}     
     
        getAriaValueText={valuetext}
        aria-labelledby="vertical-slider"
      />

          </div>
</div>
        <div className="dots dots1" style={{marginTop:-107}}>
          <img src={dot2} onClick={()=>{settitle("CLOVE BUD");sentcontent("Powerful at fighting off intestinal parasites. Strong antibacterial properties. Used to treat indigestion, motion sickness and bloating."); handleClickOpen()}}  title="CLOVE BUD: Powerful at fighting off intestinal parasites. Strong antibacterial properties. Used to treat indigestion, motion sickness and bloating."  style={{zIndex:102, marginRight:20,marginBottom:9 ,position: "relative"}} alt="kara" width="7px"/>
          <img src={dot3} onClick={()=>{settitle("CURRY LEAVES");sentcontent("Anti-inflammatory and antibacterial. Protects and stimulates insulin-producing pancreatic cells. Helps lower blood sugar."); handleClickOpen()}} title="CURRY LEAVES: Anti-inflammatory and antibacterial. Protects and stimulates insulin-producing pancreatic cells. Helps lower blood sugar."  style={{zIndex:102, marginRight:10,marginBottom:4 ,position: "relative"}} alt="kara" width="8px"/>
          <img src={dot1} onClick={()=>{settitle("CORIANDER");sentcontent("Eases digestive discomfort and stomach bloating. Lowers high blood pressure and sugars."); handleClickOpen()}} title="CORIANDER: Eases digestive discomfort and stomach bloating. Lowers high blood pressure and sugars."  style={{zIndex:102, position: "relative"}} alt="kara" width="7px"/>
          <img src={dot3} onClick={()=>{settitle("CELERY");sentcontent("Reduces inflammation. Rich in antioxidants and vitamins. Protects entire digestive tract."); handleClickOpen()}} title="CELERY: Reduces inflammation. Rich in antioxidants and vitamins. Protects entire digestive tract."  style={{zIndex:102, marginLeft:17,marginBottom:1 , position: "relative"}} alt="kara" width="8px"/>
          <img src={dot1} onClick={()=>{settitle("ONION");sentcontent("High in vitamin C. Supports collagen production, immune health, tissue repair and iron absorption."); handleClickOpen()}} title="ONION: High in vitamin C. Supports collagen production, immune health, tissue repair and iron absorption. "  style={{zIndex:102, marginLeft:17,marginBottom:8 , position: "relative"}} alt="kara" width="6px"/>
        </div>
        <div className="dots dots2" style={{marginTop:-20}}>
          <img src={dot1} onClick={()=>{settitle("TOMATO");sentcontent("Benefits skin health. Lowers risk of heart disease and cancer."); handleClickOpen()}} title="TOMATO: Benefits skin health. Lowers risk of heart disease and cancer."  style={{zIndex:102, marginRight:10,marginBottom:10 ,position: "relative"}} alt="kara" width="8px"/>
          <img src={dot2} onClick={()=>{settitle("LEMON");sentcontent(" Defends body against harmful pathogens and has purifying, cleansing effect on body by promoting natural detoxification. Has a positive impact on digestion."); handleClickOpen()}} title="LEMON: Defends body against harmful pathogens and has purifying, cleansing effect on body by promoting natural detoxification. Has a positive impact on digestion."  style={{zIndex:102, marginRight:20,marginBottom:5 ,position: "relative"}} alt="kara" width="5px"/>
          <img src={dot1} onClick={()=>{settitle("CARROT");sentcontent("Highly antibacterial. Supports healthy grow of hair, nails and skin. Improves vision. Rich in Vitamin A, K and C."); handleClickOpen()}} title="CARROT: Highly antibacterial. Supports healthy grow of hair, nails and skin. Improves vision. Rich in Vitamin A, K and C."  style={{zIndex:102,marginLeft:10, marginBottom:5 ,position: "relative"}} alt="kara" width="6px"/>
          <img src={dot3} onClick={()=>{settitle("PEPPERMINT");sentcontent("Relieves from gas, bloating and indigestion by relaxing digestive system. Increases blood flow."); handleClickOpen()}} title="PEPPERMINT: Relieves from gas, bloating and indigestion by relaxing digestive system. Increases blood flow."  style={{zIndex:102, marginLeft:22,marginBottom:9 , position: "relative"}} alt="kara" width="5px"/>
        </div>
        <div className="dots dots3" style={{marginTop:-22}}>
          <img src={dot2} onClick={()=>{settitle("EXTRA VIRGIN OLIVE OIL");sentcontent("Healthiest fat on Earth. Significantly decreases risk of cardiac death by protecting brain and heart health. Highly anti-inflammatory."); handleClickOpen()}} title="EXTRA VIRGIN OLIVE OIL: Healthiest fat on Earth. Significantly decreases risk of cardiac death by protecting brain and heart health. Highly anti-inflammatory."  style={{zIndex:102, marginRight:25,marginBottom:10 ,position: "relative"}} alt="kara" width="8px"/>
          <img src={dot1} onClick={()=>{settitle("MCT");sentcontent("Pure source of healthy fatty acids. Helps protect heart health. Improves energy levels, mood n performance. Supports nutrient absorption. Antibacterial, antiviral, and antifungal properties."); handleClickOpen()}} title="MCT: Pure source of healthy fatty acids. Helps protect heart health. Improves energy levels, mood n performance. Supports nutrient absorption. Antibacterial, antiviral, and antifungal properties."  style={{zIndex:102, marginRight:15,marginBottom:5 ,position: "relative"}} alt="kara" width="7px"/>
          <img src={dot3} onClick={()=>{settitle("HOT PEPPER MIX");sentcontent("Their core compound “capsaicin” helps prevent blood clots, promoting healthy heart and a digestive tract. It is powerful at fighting flu, cold and fungal infections."); handleClickOpen()}} title="HOT PEPPER MIX: Their core compound “capsaicin” helps prevent blood clots, promoting healthy heart and a digestive tract. It is powerful at fighting flu, cold and fungal infections."  style={{zIndex:102,marginLeft:2, marginBottom:5 ,position: "relative"}} alt="kara" width="5px"/>
          <img src={dot2} onClick={()=>{settitle("LEMON ZEST");sentcontent("Supports oral health. Antimicrobial and antifungal properties. High in antioxidants."); handleClickOpen()}} title="LEMON ZEST: Supports oral health. Antimicrobial and antifungal properties. High in antioxidants."  style={{zIndex:102, marginLeft:10,marginBottom:9 , position: "relative"}} alt="kara" width="6px"/>
          <img src={dot1} onClick={()=>{settitle("HIMALAYAN PINK SALT");sentcontent("Significantly higher in minerals than a regular salt."); handleClickOpen()}} title="HIMALAYAN PINK SALT: Significantly higher in minerals than a regular salt."  style={{zIndex:102, marginLeft:10,marginBottom:9 , position: "relative"}} alt="kara" width="5px"/>

        </div>

        <div className="btns">
          <p className={values[8]>96?("notice"):("notice hiddennotice")}> * Bottle is full. To increase volume of your ingredients,<br />make space by reducing other ingredients first.</p>
          <div className="results">
            <div className="resultstext">
              <p>Your custom KARA is: </p> 
            </div>
            <div>
              <div className="btncont">
              
                <Button style={{width:270}} variant="outlined">{!defkara===null?(defkara):(karatype)}</Button>
                <Button onClick={openlink} variant="contained">Select</Button>
              </div>
            </div>
          </div>
        </div>
      </header>

    </div>
  );
}

export default App;
